var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_vm.displayAgreement ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-0"
  }, [_vm._v("Deklarasi oleh Pemberi")]), _c('div', {
    staticClass: "box-body mt-4",
    staticStyle: {
      "font-size": "10pt"
    }
  }, [_c('b', [_vm._v(" A. SAYA DENGAN INI BERSETUJU DAN MENGAKU BAHAWA, SAYA PERCAYA DAN FAHAM PERKARA TERSEBUT: ")]), _c('br'), _c('br'), _vm._m(0), _c('br'), _c('b', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" B. DEKLARASI AKTA PENCEGAHAN PENGUBAHAN WANG HARAM, PENCEGAHAN PEMBIAYAAN KEGANASAN DAN HASIL DARIPADA AKTIVITI HARAM 2001 (AMLA 2001) ")]), _c('br'), _c('br'), _c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" Ia adalah polisi AMANAH WARISAN BERHAD untuk mematuhi Program Pencegahan Pengubahan Wang Haram & Pencegahan Pembiayaan Keganasan (AML/CFT) di bawah Akta Pencegahan Pengubahan Wang Haram, Pencegahan Pembiayaan Keganasan dan Hasil Daripada Aktiviti Haram 2001 (AMLA 2001), kaedah dan peraturan yang diselia oleh Bank Negara Malaysia di bawah Garis Panduan AML/CFT – Designated Non-Financial Business and Professions (DNFBPs) & Other Non-Financial Sectors (Sector 5). ")]), _c('br'), _vm._m(1), _c('br'), _c('b', [_vm._v("C. AKTA PERLINDUNGAN DATA PERIBADI 2010")]), _c('br'), _c('br'), _c('p', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Dengan menyempurnakan borang ini anda membenarkan maklumat peribadi anda untuk digunakan oleh Amanah Warisan Berhad untuk memberikan anda perkhidmatan Amanah Warisan Berhad yang bersesuaian dengan anda. Maklumat peribadi yang diberikan oleh anda akan digunakan untuk pentadbiran dan pengurusan perkhidmatan yang diberikan kepada anda. Maklumat peribadi anda boleh digunakan untuk memberi anda maklumat lanjut mengenai produk dan perkhidmatan yang sesuai dengan anda daripada Amanah Warisan Berhad.Maklumat tersebut adalah sulit dan persendirian dan tidak akan dikemukakan kepada pihak ketiga tanpa kebenaran anda Semua maklumat yang diberikan oleh anda dalam borang ini adalah tepat, lengkap dan terkini. ")]), _c('br'), !_vm.amendmentId || _vm.amendment && _vm.amendment.status != 0 ? _c('div', {
    staticClass: "custom-control custom-checkbox text-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.acceptPdpa,
      expression: "acceptPdpa"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "disabled": _vm.checkBoxdisable,
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.acceptPdpa) ? _vm._i(_vm.acceptPdpa, "1") > -1 : _vm.acceptPdpa
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.acceptPdpa,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.acceptPdpa = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.acceptPdpa = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.acceptPdpa = $$c;
        }
      }, _vm.acceptPdpaFunc]
    }
  }), _vm._m(2)]) : _vm._e(), _vm.checkPdpa && !_vm.btnSetujuSign && _vm.trust ? _c('div', [_vm.trust && [0, 1].includes(_vm.trust.status) || _vm.amendmentId ? _c('div', {
    staticClass: "d-flex justify-content-center m-4"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-otp-input', {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      "separator": "-",
      "num-inputs": 6,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-change": _vm.handleOnChange,
      "on-complete": _vm.handleOnComplete
    }
  })], 1)]) : _vm._e(), _vm.trust && [0, 1].includes(_vm.trust.status) || _vm.amendmentId ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [!_vm.otpSent ? _c('b-btn', {
    staticClass: "btn-sm btn-warning m-2",
    on: {
      "click": _vm.requestOtp
    }
  }, [_vm._v(_vm._s(_vm.btnotptext))]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_vm.otpSent ? _c('span', [_c('small', [_vm._v("OTP telah dihantar ke nombor telefon bimbit anda.")])]) : _vm._e()])]) : _vm._e(), _vm.btnSetujuSign && !_vm.amendmentId ? _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "100%",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4"
    }
  }, [_vm._v(" Tandatangan disini ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()]), _vm.trust && _vm.trust.status != 0 ? _c('div', [_vm.checkBoxdisable && _vm.signature.public_path ? _c('img', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "src": _vm.signature.public_path,
      "alt": "",
      "srcset": ""
    }
  }) : _vm._e(), _vm.checkBoxdisable ? _c('div', [_vm._v(" [ "), _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.trust.user_detail.name)
    }
  }), _vm._v("] ")]) : _vm._e()]) : _vm._e()])]), !_vm.amendmentId && _vm.trust && [0, 1].includes(_vm.trust.status) ? _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.finish
    }
  }, [_vm._v(" Selesai ")])])])]) : _vm._e(), _vm.amendmentId && !_vm.checkPdpa && _vm.amendment && _vm.amendment.status == 0 ? _c('div', {
    staticClass: "d-flex justify-content-around"
  }, [_c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.backButton
    }
  }, [_vm._v(" Kembali ")]), _c('b-button', {
    staticClass: "btn btn-primary m-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openotp
    }
  }, [_vm._v(" Hantar Permohonan ")])], 1) : _vm._e(), _vm.amendmentId && _vm.otpSent ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary m-1",
    on: {
      "click": _vm.updateAmendmentStatus
    }
  }, [_vm._v(" Hantar ")])], 1) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    attrs: {
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "2%",
      "valign": "top"
    }
  }, [_vm._v("a)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    },
    attrs: {
      "width": "98%"
    }
  }, [_vm._v(" Butiran dan maklumat yang diberikan oleh saya dalam borang permohonan ini bagi penubuhan Amanah, setelah membuat semua pertanyaan yang munasabah, benar, lengkap dan betul; saya akan memaklumkan Amanah Warisan Berhad secara bertulis dengan serta-merta jika apa-apa maklumat yang terkandung dalam permohonan ini tidak tepat atau berubah dalam apa jua keadaan. Saya mengakui dan mengesahkan Amanah Warisan Berhad boleh mempercayai semua deklarasi ke atas identiti dan semua maklumat yang berkenaan untuk penubuhan akaun amanah melalui deklarasi. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("b)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya bersetuju untuk memaklumkan Amanah Warisan Berhad semua perubahan ke atas kediaman saya dan/atau kewarganegaraan dan/atau Benefisiari dan mana-mana pihak yang berkaitan Amanah. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("c)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya mengesahkan apa-apa wang dan/atau lain-lain aset yang dipindahkan sekarang atau pada bila-bila pada masa akan datang ke dalam Amanah atau mana-mana syarikat adalah milik saya atau akan menjadi milik saya. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("d)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya mengesahkan bahawa apa-apa wang dan/atau aset lain di dalam Amanah oleh Pemberi adalah bebas daripada apa-apa tanggungjawab, caj atau bebanan. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("e)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya mengesahkan bahawa saya tidak bankrap dan tidak ada prosiding untuk pengisytiharan saya bankrap. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("f)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya faham bahawa dengan memindahkan wang dan/atau lain-lain aset ke dalam Tabung Amanah, saya memindahkan pemilikan wang dan/atau aset lain kepada sesiapa yang dilantik sebagai Pemegang Amanah untuk manfaat Benefisiari. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("g)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya beraku janji akan memberitahu Pemegang Amanah melalui notis bertulis secepat mungkin dalam apa jua cara, jika ada perubahan yang boleh menjejaskan struktur Amanah. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("h)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya faham bahawa Perancang Harta Islam adalah untuk memudahkan urusan Amanah Warisan Berhad untuk menjadi pemegang amanah berkenaan suatu amanah dinyatakan dan saya dengan ini bersetuju dan memberi keizinan kepada Amanah Warisan Berhad untuk mendapatkan butiran dan maklumat saya dari Perancang Harta Islam (jika berkenaan) mengenai semua aspek permohonan Amanah sehingga permohonan selesai. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    attrs: {
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "width": "2%",
      "valign": "top"
    }
  }, [_vm._v("a)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    },
    attrs: {
      "width": "98%"
    }
  }, [_vm._v(" Saya mengesahkan bahawa penubuhan Amanah ini: ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" i) Tidak melibatkan dana daripada hasil jenayah sama ada secara langsung atau tidak langsung, dan ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ii) Tidak akan melanggar mana-mana undang-undang atau peraturan dibawah AMLA 2001. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("b)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya faham dan bersetuju bahawa Amanah termasuk dana dan aset yang ditubuhkan dalam Amanah bukan dari dan tidak akan digunakan untuk sebarang aktiviti yang menyalahi undang-undang seperti: ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" i) Menerima dan/atau mengedarkan hasil dari pengedaran dadah, rasuah atau apa-apa aktiviti jenayah lain; dan ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" ii) Melibatkan diri dalam apa-apa bentuk pengubahan wang haram dan / atau pembiayaan keganasan. ")])]), _c('tr', [_c('td', {
    attrs: {
      "valign": "top"
    }
  }, [_vm._v("c)")]), _c('td', {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(" Saya memahami bahawa mengikut AMLA 2001, Amanah Warisan Berhad tidak akan membenarkan kemudahan mereka digunakan sebagai saluran untuk pengubahan wang haram atau pembiayaan keganasan. Saya memberi jaminan kepada Amanah Warisan Berhad bahawa saya akan berhati-hati agar tidak membenarkan dana dan / atau mana-mana aset lain daripada hasil aktiviti haram disalurkan melalui akaun amanah saya. ")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_c('strong', [_vm._v("SAYA MEMAHAMI DEKLARASI DI ATAS")])]);
}]

export { render, staticRenderFns }