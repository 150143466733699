var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.trust ? _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_vm.isKoperate && _vm.hibah ? _c('part-h-corporation', {
    attrs: {
      "trust": _vm.hibah,
      "amendment": _vm.amendmentDetails,
      "receiptShow": _vm.receipt
    }
  }) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }